.imageGroup2-0-2-16 {
  position: relative;
}
.imageGroup2-0-2-16 img {
  position: absolute;
  clip-path: polygon(0% 0%, 0% 0%, 100% 100%, 0% 100%);
}
.gallery-0-2-9 {
  height: 100vh;
  display: flex;
  position: relative;
  max-width: initial;
  background: #36454F;
  flex-direction: column;
}
.gallery-0-2-9 ul {
  gap: 3em;
  margin: 0;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 1em;
  scroll-snap-type: x proximity;
}
.gallery-0-2-9 ul li {
  cursor: pointer;
  max-width: 80vw;
  background: #9198e5;
  list-style: none;
  object-fit: cover;
  border-radius: 0.5em;
  scroll-snap-align: start;
}
.gallery-0-2-9 ul img {
  height: 50%;
  display: block;
  padding: 1.5em 1.5em 0.75em;
  object-fit: cover;
}
.gallery-0-2-9 ul li figure {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}
.gallery-0-2-9 ul li.focused {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  max-width: initial;
  border-radius: 0;
}
.gallery-0-2-9 ul li.focused::after {
  top: 0;
  right: 0;
  width: 3em;
  filter: invert(1);
  height: 3em;
  content: '';
  position: absolute;
  background: url(/src/images/icon-close.svg) center no-repeat;
}
.gallery-0-2-9 ul li.focused img {
  height: 75%;
  object-fit: contain;
}
.carouselHolder-0-2-10 {
  height: 100%;
  display: flex;
}
.details-0-2-11 {
  cursor: initial;
  margin: 0;
  display: flex;
  padding: 0.75em 1.5em 1.5em;
  overflow: hidden;
  flex-grow: 1;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 0 0 0.5em 0.5em;
  flex-direction: column;
  justify-content: space-between;
}
.focused .details-0-2-11 {
  border-radius: 0;
}
.description-0-2-12 {
  overflow: auto;
}
.locationDate-0-2-13 {
  gap: 1em;
  display: flex;
  font-size: 0.8em;
  align-items: flex-end;
  justify-content: space-between;
}
.locationDate-0-2-13 time {
  text-align: right;
}
.galleryPrev-0-2-14 {
  top: 50%;
  left: 1em;
  border: 0;
  cursor: pointer;
  display: flex;
  opacity: 0.8;
  outline: 0 solid black;
  padding: 0;
  position: absolute;
  font-size: 3em;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: outline 100ms ease-in-out;
  align-items: center;
  text-shadow: 0px 0px 5px white;
  border-radius: 100%;
  backdrop-filter: blur(2px);
  justify-content: center;
}
.galleryPrev-0-2-14:hover, .galleryPrev-0-2-14:active, .galleryPrev-0-2-14:focus {
  opacity: 1;
  outline-width: 0.1em;
}
.galleryNext-0-2-15 {
  top: 50%;
  right: 1em;
  border: 0;
  cursor: pointer;
  display: flex;
  opacity: 0.8;
  outline: 0 solid black;
  padding: 0;
  position: absolute;
  font-size: 3em;
  transform: translate(50%, -50%);
  background: rgba(255, 255, 255, 0.8);
  transition: outline 100ms ease-in-out;
  align-items: center;
  text-shadow: 0px 0px 5px white;
  border-radius: 100%;
  backdrop-filter: blur(2px);
  justify-content: center;
}
.galleryNext-0-2-15:hover, .galleryNext-0-2-15:active, .galleryNext-0-2-15:focus {
  opacity: 1;
  outline-width: 0.1em;
}
.heroWrapper-0-2-5 {
  height: 95vh;
  display: flex;
  position: relative;
  box-shadow: 0 0px 1em 0px black;
  align-items: center;
  text-shadow: 0 0 1em black, 0 0 1em black, 0 0 1em black;
  flex-direction: column;
  padding-bottom: 2em;
  justify-content: end;
}
@media (max-width: 600px) {
  .heroWrapper-0-2-5 {
    height: calc(95vh - 3em);
  }
}
  .heroImg-0-2-6 {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: absolute;
    background: #e0d6cf;
    object-fit: contain;
    object-position: center top;
  }
@media
        (max-aspect-ratio: 443/742),
        (max-aspect-ratio: 618/680) and (min-width: 600px) {
  .heroImg-0-2-6 {
    object-fit: cover;
  }
}
  .h1-0-2-7 {
    padding: 0 1em;
    font-size: 6vh;
    text-align: center;
    text-transform: uppercase;
  }
  .subHeader-0-2-8 {
    font-size: 4vh;
  }
  .list-0-2-21 {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .figure-0-2-22 {
    padding: 1em;
    background: rgba(0, 0, 0, 0.3);
  }
  .author-0-2-23 {
    text-align: right;
  }
  .author-0-2-23::before {
    content: '—';
    margin-right: 0.5em;
  }
  .recaptchaBlurb-0-2-25 {
    margin: 1em 0;
    font-size: 0.70em;
  }
  .grecaptcha-badge {
    visibility: hidden;
  }
  .statusBlock-0-2-24 {
    color: #333;
    border: 0.2em solid black;
    padding: 0.5em;
    border-color: #333;
  }
  .statusBlock-0-2-24.success, .statusBlock-0-2-24.error {
    display: block;
  }
  .statusBlock-0-2-24.success {
    border-color: #61ad61;
    background-color: #b2ffb2;
  }
  .statusBlock-0-2-24.error {
    border-color: #979708;
    background-color: #e4e47e;
  }
  .memorialWall-0-2-17 form input, .memorialWall-0-2-17 form textarea {
    width: 100%;
  }
  .submitWrapper-0-2-18 {
    gap: 1em;
    display: flex;
    align-items: center;
  }
  .success-0-2-19 {
    display: none;
  }
  .submit-success .success-0-2-19 {
    display: block;
  }
  .error-0-2-20 {
    display: none;
  }
  .submit-error .error-0-2-20 {
    display: block;
  }
  .menuCheckbox-0-2-3 {
    cursor: pointer;
    filter: invert(1);
    margin: 0;
    display: none;
    appearance: none;
    background: url(/src/images/icon-menu.svg) center no-repeat;
  }
  .menuCheckbox-0-2-3:checked {
    background-image: url(/src/images/icon-close.svg);
  }
@media (max-width: 600px) {
  .menuCheckbox-0-2-3 {
    display: block;
    padding: 1em;
    align-self: end;
  }
}
  .nav-0-2-4 {
    font-size: 1.5em;
    background: #333;
  }
  .nav-0-2-4 ul {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: auto;
  }
@media (max-width: 600px) {
  .nav-0-2-4 {
    width: 100%;
    display: flex;
    text-align: right;
    flex-direction: column;
  }
  .nav-0-2-4 ul {
    display: none;
  }
  .nav-0-2-4 input:checked + ul {
    height: calc(100vh - 2em);
    display: block;
    background: #333;
  }
}
  .nav-0-2-4 li {
    display: block;
    border-bottom: 0.3em solid #333;
  }
  .nav-0-2-4 a {
    text-decoration: none;
  }
  .nav-0-2-4 li a {
    color: #fff;
    width: 100%;
    display: block;
    padding: 1em;
    background: linear-gradient(to right, #111, #9198e5);
    box-sizing: border-box;
    transition: all 500ms ease-in-out;
    background-size: 200%;
  }
  .nav-0-2-4 li a:hover, .nav-0-2-4 li a:focus {
    background-position: 100%;
  }
  .wrapper-0-2-1 {
    height: 100%;
    display: flex;
  }
@media (max-width: 600px) {
  .wrapper-0-2-1 {
    flex-direction: column;
  }
}
  .main-0-2-2 {
    flex-grow: 1;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }
  * {
    box-sizing: border-box;
  }
  html {
    width: 100%;
    height: 100%;
    margin: 0;
    font-size: 20px;
  }
  body {
    color: #efefef;
    width: 100%;
    height: 100%;
    margin: 0;
    overflow-x: clip;
    font-family: 'Roboto Slab', helvetica, serif;
    border-color: #efefef;
  }
  h1 {
    font-family: 'Roboto Slab', helvetica, serif;
  }
  h2 {
    font-family: 'Roboto Slab', helvetica, serif;
  }
  h3 {
    font-family: 'Roboto Slab', helvetica, serif;
  }
  h4 {
    font-family: 'Roboto Slab', helvetica, serif;
  }
  h5 {
    font-family: 'Roboto Slab', helvetica, serif;
  }
  h6 {
    font-family: 'Roboto Slab', helvetica, serif;
  }
  main {
    z-index: 0;
    background-size: 34.64101615137755px 60px;
    background-color: grey;
    background-image: 
    repeating-linear-gradient(120deg, 
      rgba(255, 255, 255, .1), 
      rgba(255, 255, 255, .1) 1px, 
      transparent 1px, 
      transparent 30px
    ),
    repeating-linear-gradient(60deg, 
      rgba(255, 255, 255, .1), 
      rgba(255, 255, 255, .1) 1px, 
      transparent 1px, 
      transparent 30px
    ),
    linear-gradient(60deg, 
      rgba(0, 0, 0, .1) 25%, 
      transparent 25%, 
      transparent 75%, 
      rgba(0, 0, 0, .1) 75%, 
      rgba(0, 0, 0, .1)
    ),
    linear-gradient(120deg, 
      rgba(0, 0, 0, .1) 25%, 
      transparent 25%, 
      transparent 75%, 
      rgba(0, 0, 0, .1) 75%, 
      rgba(0, 0, 0, .1)
    );
  }
  section {
    width: 100%;
    padding: 1em;
    max-width: 1000px;
  }
  address {
    font-style: normal;
  }
  figure {
    margin: 0;
  }
  blockquote {
    color: #efefef;
    margin: 0;
    padding: 0 1em;
    font-style: italic;
    border-left: 0.25em solid;
    border-color: #efefef;
  }
  input {
    padding: 0.5em;
    font-size: 1em;
    font-family: 'Roboto Slab', helvetica, serif;
  }
  textarea {
    padding: 0.5em;
    font-size: 1em;
    font-family: 'Roboto Slab', helvetica, serif;
  }
  button {
    padding: 0.5em;
    font-size: 1em;
    font-family: 'Roboto Slab', helvetica, serif;
  }
  a {
    color: #ced2ff;
  }
  a:visited {
    color: #e5b3ff;
  }
  .light-background a, a.light-background {
    color: #6f7bff;
  }
  .light-background a:visited, a.light-background:visited {
    color: #bf44ff;
  }